import React, { useEffect, useState } from "react";
import { APP_CONFIG, axiosClient } from "../../constants";
import Cookies from "js-cookie";

export default function Template({ children, active, topHeading }) {
  const [userData, setUserData] = useState(null);
  const token = Cookies.get("USER_TOKEN");

  const getUserData = async () => {
    try {
      const response = await axiosClient.get("/accounts/get-user-details", {
        headers: { Authorization: "Token " + token },
      });
      setUserData(response.data.data);
    } catch (error) {}
  };

  const logout = () => {
    Cookies.remove("USER_DATA");
    Cookies.remove("USER_TOKEN");
    window.location.href = "/";
  };

  useEffect(() => {
    getUserData();
    const script = document.createElement("script");
    script.innerHTML = `
    window.addEventListener('scroll', function() {
      if (window.scrollY > 10) {
        document.getElementsByClassName('top-btns--withSidebar')[0].classList.add('blurred');
      } else {
        document.getElementsByClassName('top-btns--withSidebar')[0].classList.remove('blurred');
      }
    });`;
    document.body.appendChild(script);
  }, []);

  return (
    <>
      <div className="bg-setup">
        <div className="blobs" />
      </div>

      {/* SideBar */}
      <div className="gradient-block-outer sidebar">
        <div className="gradient-block-bg gradient-block-bg--verticalCard">
          <div className="gradient-block-bg-inner" />
        </div>
        <div className="gradient-block-inner sidebar__inner">
          <div className="logo-small">
            <img src="/assets/images/logo-small.svg" alt="Logo small image" />
          </div>
          <ul className="list-unstyled">
            <li>
              <a
                href="/dashboard"
                className={`app-links ${
                  active === "dashboard" ? "active" : ""
                }`}
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                data-bs-title="Dashboard"
              >
                <svg
                  width={23}
                  height={24}
                  viewBox="0 0 23 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M21.1034 7.22008L13.6552 0.915623C12.9724 0.325982 12.0885 0 11.1725 0C10.2564 0 9.37249 0.325982 8.68971 0.915623L1.24149 7.22008C0.84719 7.56056 0.532542 7.97824 0.318494 8.44531C0.104447 8.91237 -0.00408729 9.41811 0.000117675 9.92884V20.4043C0.000117675 21.3579 0.392478 22.2725 1.09088 22.9468C1.78929 23.6212 2.73653 24 3.72423 24H18.6207C19.6084 24 20.5556 23.6212 21.254 22.9468C21.9524 22.2725 22.3448 21.3579 22.3448 20.4043V9.91685C22.3472 9.40814 22.2378 8.90471 22.0239 8.43984C21.8099 7.97496 21.4961 7.55922 21.1034 7.22008ZM13.6552 21.6029H8.68971V15.61C8.68971 15.2922 8.8205 14.9873 9.0533 14.7625C9.2861 14.5378 9.60185 14.4115 9.93108 14.4115H12.4138C12.7431 14.4115 13.0588 14.5378 13.2916 14.7625C13.5244 14.9873 13.6552 15.2922 13.6552 15.61V21.6029ZM19.862 20.4043C19.862 20.7222 19.7313 21.027 19.4985 21.2518C19.2657 21.4766 18.9499 21.6029 18.6207 21.6029H16.1379V15.61C16.1379 14.6564 15.7456 13.7418 15.0472 13.0675C14.3488 12.3932 13.4015 12.0143 12.4138 12.0143H9.93108C8.94339 12.0143 7.99614 12.3932 7.29774 13.0675C6.59933 13.7418 6.20697 14.6564 6.20697 15.61V21.6029H3.72423C3.395 21.6029 3.07925 21.4766 2.84645 21.2518C2.61365 21.027 2.48286 20.7222 2.48286 20.4043V9.91685C2.48308 9.74667 2.52084 9.57849 2.59361 9.4235C2.66638 9.2685 2.77251 9.13025 2.90493 9.01793L10.3531 2.72546C10.5797 2.5333 10.8709 2.42733 11.1725 2.42733C11.474 2.42733 11.7652 2.5333 11.9918 2.72546L19.44 9.01793C19.5724 9.13025 19.6785 9.2685 19.7513 9.4235C19.8241 9.57849 19.8618 9.74667 19.862 9.91685V20.4043Z"
                    className="fill-icon"
                  />
                </svg>
              </a>
            </li>
            {/* <li>
              <a href="/calendar" className={`app-links ${active === "calendar" ? 'active' : ''}`} data-bs-toggle="tooltip"
                data-bs-placement="right"
                data-bs-title="Calendar">
                <svg
                  width={24}
                  height={24}
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M20.4 2.4H18V1.2C18 0.88174 17.8736 0.576515 17.6485 0.351472C17.4235 0.126428 17.1183 0 16.8 0C16.4817 0 16.1765 0.126428 15.9515 0.351472C15.7264 0.576515 15.6 0.88174 15.6 1.2V2.4H8.4V1.2C8.4 0.88174 8.27357 0.576515 8.04853 0.351472C7.82348 0.126428 7.51826 0 7.2 0C6.88174 0 6.57652 0.126428 6.35147 0.351472C6.12643 0.576515 6 0.88174 6 1.2V2.4H3.6C2.64522 2.4 1.72955 2.77928 1.05442 3.45442C0.379285 4.12955 0 5.04522 0 6V20.4C0 21.3548 0.379285 22.2705 1.05442 22.9456C1.72955 23.6207 2.64522 24 3.6 24H20.4C21.3548 24 22.2705 23.6207 22.9456 22.9456C23.6207 22.2705 24 21.3548 24 20.4V6C24 5.04522 23.6207 4.12955 22.9456 3.45442C22.2705 2.77928 21.3548 2.4 20.4 2.4ZM21.6 20.4C21.6 20.7183 21.4736 21.0235 21.2485 21.2485C21.0235 21.4736 20.7183 21.6 20.4 21.6H3.6C3.28174 21.6 2.97652 21.4736 2.75147 21.2485C2.52643 21.0235 2.4 20.7183 2.4 20.4V12H21.6V20.4ZM21.6 9.6H2.4V6C2.4 5.68174 2.52643 5.37652 2.75147 5.15147C2.97652 4.92643 3.28174 4.8 3.6 4.8H6V6C6 6.31826 6.12643 6.62348 6.35147 6.84853C6.57652 7.07357 6.88174 7.2 7.2 7.2C7.51826 7.2 7.82348 7.07357 8.04853 6.84853C8.27357 6.62348 8.4 6.31826 8.4 6V4.8H15.6V6C15.6 6.31826 15.7264 6.62348 15.9515 6.84853C16.1765 7.07357 16.4817 7.2 16.8 7.2C17.1183 7.2 17.4235 7.07357 17.6485 6.84853C17.8736 6.62348 18 6.31826 18 6V4.8H20.4C20.7183 4.8 21.0235 4.92643 21.2485 5.15147C21.4736 5.37652 21.6 5.68174 21.6 6V9.6Z"
                    className="fill-icon"
                  />
                </svg>
              </a>
            </li> */}
            <li className="d-lg-none qr-button">
              <a
                href="#"
                className={`app-links ${active === "calendar" ? "active" : ""}`}
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                data-bs-title="QR"
              >
                <img src="/assets/images/mob-qr-button-icon.svg" alt="qr" />
              </a>
            </li>
            {/* <li>
              <a href="/offers" className={`app-links ${active === "offers" ? 'active' : ''}`} data-bs-toggle="tooltip"
                data-bs-placement="right"
                data-bs-title="Offers">
                <svg
                  width={28}
                  height={30}
                  viewBox="0 0 28 30"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M22.0724 5.64911L22.6157 7.32274C22.7741 7.85256 23.1013 8.3123 23.5447 8.62817L24.9192 9.57656C25.3806 9.89461 25.7219 10.3655 25.886 10.9107C26.0501 11.4559 26.0272 12.0424 25.821 12.5724L25.2777 13.928C25.0412 14.5273 25.0412 15.1976 25.2777 15.7969L25.821 17.119C26.0267 17.6383 26.056 18.2136 25.9042 18.7521C25.7524 19.2906 25.4284 19.7608 24.9844 20.087L23.762 20.9851C23.314 21.3122 22.9881 21.7865 22.8384 22.3296L22.3984 23.914C22.2452 24.4702 21.9081 24.9543 21.4452 25.2831C20.9822 25.6119 20.4222 25.765 19.8613 25.7159L18.5411 25.6043C17.9164 25.5511 17.2961 25.7513 16.8135 26.1622L15.5423 27.2166C15.1088 27.5796 14.5667 27.7778 14.0075 27.7778C13.4483 27.7778 12.9062 27.5796 12.4728 27.2166L11.3862 26.3017C10.9507 25.9373 10.4051 25.74 9.8433 25.7438H7.93641C7.38765 25.7453 6.85469 25.555 6.42523 25.2042C5.99572 24.8534 5.69524 24.363 5.57316 23.8136L5.22547 22.2961C5.08954 21.6839 4.73223 21.1475 4.22584 20.7955L3.0578 19.914C2.61568 19.6052 2.28579 19.1546 2.11966 18.6327C1.95352 18.1108 1.96056 17.5472 2.13967 17.0298L2.68294 15.5012C2.88395 14.9452 2.88395 14.3335 2.68294 13.7774L2.19399 12.4385C1.99847 11.9067 1.98618 11.3222 2.15915 10.7822C2.33213 10.2422 2.67995 9.77935 3.14472 9.47057L4.43229 8.61701C4.93009 8.28642 5.29327 7.78054 5.45364 7.19443L5.83393 5.78858C5.98632 5.22881 6.32429 4.74134 6.78955 4.41024C7.25476 4.07919 7.81797 3.92544 8.38189 3.97548L9.59883 4.08148C10.225 4.13214 10.8459 3.93225 11.3319 3.5236L12.489 2.56407C12.9237 2.19928 13.4679 2 14.0292 2C14.5906 2 15.1347 2.19928 15.5694 2.56407L16.5799 3.43434C17.0659 3.84701 17.6901 4.04734 18.3184 3.99222L19.5951 3.89738C20.1396 3.86112 20.6802 4.0142 21.1297 4.33202C21.5791 4.64979 21.9112 5.11378 22.0724 5.64911Z"
                    className="stroke-icon"
                    strokeWidth="2.5"
                    strokeMiterlimit={10}
                  />
                  <path
                    d="M19.2855 9.42003L8.49609 20.505M10.9898 12.5777C12.1209 12.5777 13.0379 11.6361 13.0379 10.4745C13.0379 9.31292 12.1209 8.37134 10.9898 8.37134C9.85863 8.37134 8.94163 9.31292 8.94163 10.4745C8.94163 11.6361 9.85863 12.5777 10.9898 12.5777ZM17.2374 21.4144C18.3686 21.4144 19.2856 20.4727 19.2856 19.3112C19.2856 18.1496 18.3686 17.208 17.2374 17.208C16.1063 17.208 15.1893 18.1496 15.1893 19.3112C15.1893 20.4727 16.1063 21.4144 17.2374 21.4144Z"
                    className="stroke-icon"
                    strokeWidth="2.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </a>
            </li> */}
            <li>
              <a
                href="/levels"
                className={`app-links ${active === "levels" ? "active" : ""}`}
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                data-bs-title="Levels"
              >
                <svg
                  width={26}
                  height={24}
                  viewBox="0 0 26 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M25.9564 9.07852C25.8744 8.84897 25.7253 8.64735 25.5271 8.4981C25.3289 8.34886 25.0902 8.2584 24.8399 8.2377L17.453 7.19608L14.1425 0.707936C14.0362 0.495758 13.8702 0.316817 13.6635 0.191612C13.4569 0.0664062 13.2179 0 12.9741 0C12.7302 0 12.4912 0.0664062 12.2846 0.191612C12.0779 0.316817 11.912 0.495758 11.8057 0.707936L8.49516 7.18353L1.10821 8.2377C0.867936 8.27071 0.642046 8.36817 0.456173 8.51901C0.2703 8.66985 0.131887 8.86803 0.0566416 9.09107C-0.0122362 9.30902 -0.0184171 9.54099 0.038762 9.76209C0.0959412 9.98318 0.214322 10.185 0.3812 10.346L5.7429 15.3659L4.44467 22.494C4.39832 22.7293 4.42259 22.9725 4.51461 23.1949C4.60662 23.4172 4.76255 23.6095 4.96396 23.749C5.16028 23.8847 5.39183 23.9648 5.63261 23.9803C5.8734 23.9958 6.1139 23.9461 6.32711 23.8368L12.9741 20.4861L19.595 23.8494C19.7773 23.9488 19.983 24.0007 20.1922 24C20.4672 24.0009 20.7354 23.9174 20.9582 23.7616C21.1596 23.6221 21.3155 23.4298 21.4075 23.2074C21.4996 22.9851 21.5238 22.7419 21.4775 22.5066L20.1793 15.3784L25.541 10.3586C25.7284 10.2051 25.8669 10.0033 25.9405 9.77671C26.014 9.55013 26.0195 9.30802 25.9564 9.07852ZM17.9723 14.0984C17.82 14.2407 17.7061 14.4169 17.6405 14.6116C17.5749 14.8063 17.5595 15.0135 17.5958 15.2153L18.5305 20.4736L13.6491 17.9636C13.4613 17.867 13.2518 17.8164 13.039 17.8164C12.8262 17.8164 12.6166 17.867 12.4288 17.9636L7.54745 20.4736L8.48217 15.2153C8.51844 15.0135 8.50309 14.8063 8.43747 14.6116C8.37185 14.4169 8.25794 14.2407 8.10569 14.0984L4.21099 10.3335L9.67655 9.56795C9.88686 9.53968 10.0868 9.46196 10.2588 9.34162C10.4308 9.22129 10.5697 9.062 10.6632 8.87773L12.9741 4.09633L15.4147 8.89028C15.5083 9.07455 15.6471 9.23384 15.8191 9.35417C15.9912 9.47451 16.1911 9.55222 16.4014 9.5805L21.867 10.346L17.9723 14.0984Z"
                    className="fill-icon"
                  />
                </svg>
              </a>
            </li>
          </ul>
        </div>
      </div>

      <div
        className={`dashboard ${
          active == "offers" || active == "levels" ? "dashboard--offers" : ""
        }`}
      >
        <div className="dashboard-top-btn-row">
          {/* Top actions btn row */}
          <div className="top-btns top-btns--withSidebar">
            <div className="gradient-block-bg">
              <div className="gradient-block-bg-inner"></div>
            </div>
            <div className="gradient-block-inner">
              <div className="top-btns__left">
                <div className="logo-small me-2">
                  <div className="logo-small__bg1">
                    <div className="logo-small__bg2">
                      <img
                        src="/assets/images/logo-small.svg"
                        alt="Logo small image"
                      />
                    </div>
                  </div>
                </div>

                <h2 className="d-none d-lg-block text-white mb-0 fz20 fw600">
                  {topHeading}
                </h2>
                {/* {
                  topHeading == "Welcome Back!" && (
                    <>
                      <div className="text-white mb-0">Earning : <span clasName="fw600">{userData?.redeemable_earning}</span></div>
                      <button className='button-dash mt-2' data-bs-toggle="modal" data-bs-target="#paymentMethodModal">
                        <span className="button-dash__bg">
                          <span className="button-dash__bg-inner"></span>
                        </span>
                        <span className="button-dash__content">
                          <span className="button-dash__text">Withdraw</span>

                          <span className="button-dash__icon-outer">
                            <span className="button-dash__icon-inner">
                              <i className="icon icon-double-chevron"></i>
                            </span>
                          </span>
                        </span>
                      </button>
                    </>
                  )
                } */}
              </div>

              <ul className="nav-links">
                <li>
                  <a
                    href="/dashboard"
                    className={`app-links ${
                      active === "dashboard" ? "active" : ""
                    }`}
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    data-bs-title="Dashboard"
                  >
                    <svg
                      width={23}
                      height={24}
                      viewBox="0 0 23 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.1034 7.22008L13.6552 0.915623C12.9724 0.325982 12.0885 0 11.1725 0C10.2564 0 9.37249 0.325982 8.68971 0.915623L1.24149 7.22008C0.84719 7.56056 0.532542 7.97824 0.318494 8.44531C0.104447 8.91237 -0.00408729 9.41811 0.000117675 9.92884V20.4043C0.000117675 21.3579 0.392478 22.2725 1.09088 22.9468C1.78929 23.6212 2.73653 24 3.72423 24H18.6207C19.6084 24 20.5556 23.6212 21.254 22.9468C21.9524 22.2725 22.3448 21.3579 22.3448 20.4043V9.91685C22.3472 9.40814 22.2378 8.90471 22.0239 8.43984C21.8099 7.97496 21.4961 7.55922 21.1034 7.22008ZM13.6552 21.6029H8.68971V15.61C8.68971 15.2922 8.8205 14.9873 9.0533 14.7625C9.2861 14.5378 9.60185 14.4115 9.93108 14.4115H12.4138C12.7431 14.4115 13.0588 14.5378 13.2916 14.7625C13.5244 14.9873 13.6552 15.2922 13.6552 15.61V21.6029ZM19.862 20.4043C19.862 20.7222 19.7313 21.027 19.4985 21.2518C19.2657 21.4766 18.9499 21.6029 18.6207 21.6029H16.1379V15.61C16.1379 14.6564 15.7456 13.7418 15.0472 13.0675C14.3488 12.3932 13.4015 12.0143 12.4138 12.0143H9.93108C8.94339 12.0143 7.99614 12.3932 7.29774 13.0675C6.59933 13.7418 6.20697 14.6564 6.20697 15.61V21.6029H3.72423C3.395 21.6029 3.07925 21.4766 2.84645 21.2518C2.61365 21.027 2.48286 20.7222 2.48286 20.4043V9.91685C2.48308 9.74667 2.52084 9.57849 2.59361 9.4235C2.66638 9.2685 2.77251 9.13025 2.90493 9.01793L10.3531 2.72546C10.5797 2.5333 10.8709 2.42733 11.1725 2.42733C11.474 2.42733 11.7652 2.5333 11.9918 2.72546L19.44 9.01793C19.5724 9.13025 19.6785 9.2685 19.7513 9.4235C19.8241 9.57849 19.8618 9.74667 19.862 9.91685V20.4043Z"
                        className="fill-icon"
                      />
                    </svg>
                  </a>
                </li>
                {/* <li>
                  <a href="/calendar" className={`app-links ${active === "calendar" ? 'active' : ''}`} data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Calendar">
                    <svg width={24} height={24} viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M20.4 2.4H18V1.2C18 0.88174 17.8736 0.576515 17.6485 0.351472C17.4235 0.126428 17.1183 0 16.8 0C16.4817 0 16.1765 0.126428 15.9515 0.351472C15.7264 0.576515 15.6 0.88174 15.6 1.2V2.4H8.4V1.2C8.4 0.88174 8.27357 0.576515 8.04853 0.351472C7.82348 0.126428 7.51826 0 7.2 0C6.88174 0 6.57652 0.126428 6.35147 0.351472C6.12643 0.576515 6 0.88174 6 1.2V2.4H3.6C2.64522 2.4 1.72955 2.77928 1.05442 3.45442C0.379285 4.12955 0 5.04522 0 6V20.4C0 21.3548 0.379285 22.2705 1.05442 22.9456C1.72955 23.6207 2.64522 24 3.6 24H20.4C21.3548 24 22.2705 23.6207 22.9456 22.9456C23.6207 22.2705 24 21.3548 24 20.4V6C24 5.04522 23.6207 4.12955 22.9456 3.45442C22.2705 2.77928 21.3548 2.4 20.4 2.4ZM21.6 20.4C21.6 20.7183 21.4736 21.0235 21.2485 21.2485C21.0235 21.4736 20.7183 21.6 20.4 21.6H3.6C3.28174 21.6 2.97652 21.4736 2.75147 21.2485C2.52643 21.0235 2.4 20.7183 2.4 20.4V12H21.6V20.4ZM21.6 9.6H2.4V6C2.4 5.68174 2.52643 5.37652 2.75147 5.15147C2.97652 4.92643 3.28174 4.8 3.6 4.8H6V6C6 6.31826 6.12643 6.62348 6.35147 6.84853C6.57652 7.07357 6.88174 7.2 7.2 7.2C7.51826 7.2 7.82348 7.07357 8.04853 6.84853C8.27357 6.62348 8.4 6.31826 8.4 6V4.8H15.6V6C15.6 6.31826 15.7264 6.62348 15.9515 6.84853C16.1765 7.07357 16.4817 7.2 16.8 7.2C17.1183 7.2 17.4235 7.07357 17.6485 6.84853C17.8736 6.62348 18 6.31826 18 6V4.8H20.4C20.7183 4.8 21.0235 4.92643 21.2485 5.15147C21.4736 5.37652 21.6 5.68174 21.6 6V9.6Z" className="fill-icon" />
                    </svg>
                  </a>
                </li> */}
                <li className="d-lg-none qr-button">
                  <a
                    href="/profile"
                    className={`app-links ${
                      active === "calendar" ? "active" : ""
                    }`}
                    title="Levels"
                  >
                    <img src="/assets/images/mob-qr-button-icon.svg" alt="qr" />
                  </a>
                </li>
                {/* <li>
                <a href="/offers" className={`app-links ${active === "offers" ? 'active' : ''}`}  data-bs-toggle="tooltip" data-bs-placement="right" data-bs-title="Offers">
                  <svg width={28} height={30} viewBox="0 0 28 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M22.0724 5.64911L22.6157 7.32274C22.7741 7.85256 23.1013 8.3123 23.5447 8.62817L24.9192 9.57656C25.3806 9.89461 25.7219 10.3655 25.886 10.9107C26.0501 11.4559 26.0272 12.0424 25.821 12.5724L25.2777 13.928C25.0412 14.5273 25.0412 15.1976 25.2777 15.7969L25.821 17.119C26.0267 17.6383 26.056 18.2136 25.9042 18.7521C25.7524 19.2906 25.4284 19.7608 24.9844 20.087L23.762 20.9851C23.314 21.3122 22.9881 21.7865 22.8384 22.3296L22.3984 23.914C22.2452 24.4702 21.9081 24.9543 21.4452 25.2831C20.9822 25.6119 20.4222 25.765 19.8613 25.7159L18.5411 25.6043C17.9164 25.5511 17.2961 25.7513 16.8135 26.1622L15.5423 27.2166C15.1088 27.5796 14.5667 27.7778 14.0075 27.7778C13.4483 27.7778 12.9062 27.5796 12.4728 27.2166L11.3862 26.3017C10.9507 25.9373 10.4051 25.74 9.8433 25.7438H7.93641C7.38765 25.7453 6.85469 25.555 6.42523 25.2042C5.99572 24.8534 5.69524 24.363 5.57316 23.8136L5.22547 22.2961C5.08954 21.6839 4.73223 21.1475 4.22584 20.7955L3.0578 19.914C2.61568 19.6052 2.28579 19.1546 2.11966 18.6327C1.95352 18.1108 1.96056 17.5472 2.13967 17.0298L2.68294 15.5012C2.88395 14.9452 2.88395 14.3335 2.68294 13.7774L2.19399 12.4385C1.99847 11.9067 1.98618 11.3222 2.15915 10.7822C2.33213 10.2422 2.67995 9.77935 3.14472 9.47057L4.43229 8.61701C4.93009 8.28642 5.29327 7.78054 5.45364 7.19443L5.83393 5.78858C5.98632 5.22881 6.32429 4.74134 6.78955 4.41024C7.25476 4.07919 7.81797 3.92544 8.38189 3.97548L9.59883 4.08148C10.225 4.13214 10.8459 3.93225 11.3319 3.5236L12.489 2.56407C12.9237 2.19928 13.4679 2 14.0292 2C14.5906 2 15.1347 2.19928 15.5694 2.56407L16.5799 3.43434C17.0659 3.84701 17.6901 4.04734 18.3184 3.99222L19.5951 3.89738C20.1396 3.86112 20.6802 4.0142 21.1297 4.33202C21.5791 4.64979 21.9112 5.11378 22.0724 5.64911Z" className="stroke-icon" strokeWidth="2.5" strokeMiterlimit={10} />
                    <path d="M19.2855 9.42003L8.49609 20.505M10.9898 12.5777C12.1209 12.5777 13.0379 11.6361 13.0379 10.4745C13.0379 9.31292 12.1209 8.37134 10.9898 8.37134C9.85863 8.37134 8.94163 9.31292 8.94163 10.4745C8.94163 11.6361 9.85863 12.5777 10.9898 12.5777ZM17.2374 21.4144C18.3686 21.4144 19.2856 20.4727 19.2856 19.3112C19.2856 18.1496 18.3686 17.208 17.2374 17.208C16.1063 17.208 15.1893 18.1496 15.1893 19.3112C15.1893 20.4727 16.1063 21.4144 17.2374 21.4144Z" className="stroke-icon" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>
                </a>
              </li> */}
                <li>
                  <a
                    href="/levels"
                    className={`app-links ${
                      active === "levels" ? "active" : ""
                    }`}
                    data-bs-toggle="tooltip"
                    data-bs-placement="right"
                    data-bs-title="Levels"
                  >
                    <svg
                      width={26}
                      height={24}
                      viewBox="0 0 26 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M25.9564 9.07852C25.8744 8.84897 25.7253 8.64735 25.5271 8.4981C25.3289 8.34886 25.0902 8.2584 24.8399 8.2377L17.453 7.19608L14.1425 0.707936C14.0362 0.495758 13.8702 0.316817 13.6635 0.191612C13.4569 0.0664062 13.2179 0 12.9741 0C12.7302 0 12.4912 0.0664062 12.2846 0.191612C12.0779 0.316817 11.912 0.495758 11.8057 0.707936L8.49516 7.18353L1.10821 8.2377C0.867936 8.27071 0.642046 8.36817 0.456173 8.51901C0.2703 8.66985 0.131887 8.86803 0.0566416 9.09107C-0.0122362 9.30902 -0.0184171 9.54099 0.038762 9.76209C0.0959412 9.98318 0.214322 10.185 0.3812 10.346L5.7429 15.3659L4.44467 22.494C4.39832 22.7293 4.42259 22.9725 4.51461 23.1949C4.60662 23.4172 4.76255 23.6095 4.96396 23.749C5.16028 23.8847 5.39183 23.9648 5.63261 23.9803C5.8734 23.9958 6.1139 23.9461 6.32711 23.8368L12.9741 20.4861L19.595 23.8494C19.7773 23.9488 19.983 24.0007 20.1922 24C20.4672 24.0009 20.7354 23.9174 20.9582 23.7616C21.1596 23.6221 21.3155 23.4298 21.4075 23.2074C21.4996 22.9851 21.5238 22.7419 21.4775 22.5066L20.1793 15.3784L25.541 10.3586C25.7284 10.2051 25.8669 10.0033 25.9405 9.77671C26.014 9.55013 26.0195 9.30802 25.9564 9.07852ZM17.9723 14.0984C17.82 14.2407 17.7061 14.4169 17.6405 14.6116C17.5749 14.8063 17.5595 15.0135 17.5958 15.2153L18.5305 20.4736L13.6491 17.9636C13.4613 17.867 13.2518 17.8164 13.039 17.8164C12.8262 17.8164 12.6166 17.867 12.4288 17.9636L7.54745 20.4736L8.48217 15.2153C8.51844 15.0135 8.50309 14.8063 8.43747 14.6116C8.37185 14.4169 8.25794 14.2407 8.10569 14.0984L4.21099 10.3335L9.67655 9.56795C9.88686 9.53968 10.0868 9.46196 10.2588 9.34162C10.4308 9.22129 10.5697 9.062 10.6632 8.87773L12.9741 4.09633L15.4147 8.89028C15.5083 9.07455 15.6471 9.23384 15.8191 9.35417C15.9912 9.47451 16.1911 9.55222 16.4014 9.5805L21.867 10.346L17.9723 14.0984Z"
                        className="fill-icon"
                      />
                    </svg>
                  </a>
                </li>
              </ul>

              <div className="top-btns__right">
                <div
                  className="user cursor-pointer"
                  onClick={() => (window.location.href = "/profile")}
                >
                  <div className="position-relative rounded-circle user__img">
                    <img
                      className="rounded-circle"
                      style={{ height: "100%", objectFit: "cover" }}
                      src={
                        userData?.profile_pic
                          ? `${APP_CONFIG.API_URL.replace("/api", "")}${
                              userData.profile_pic
                            }`
                          : "/assets/images/logo-small.svg"
                      }
                    />

                    <div className="rounded-circle dot" />
                  </div>
                  <div className="user__info">
                    <h3 className="fw600 fz20 mb-0 text-white">
                      {userData?.email}
                    </h3>
                    <span className="fz14 fw300">
                      {userData?.account_type == "user"
                        ? "User"
                        : "Independent Distributor"}
                    </span>
                  </div>
                </div>
                <button
                  className="starEarn border-0 bg-transparent text-white"
                  data-bs-toggle="modal"
                  data-bs-target="#paymentMethodModal"
                >
                  <div className="starEarn__bg">
                    <div className="starEarn__bg-inner"></div>
                  </div>
                  <div className="starEarn__content">
                    <img
                      className="star"
                      alt="..."
                      src="/assets/images/pass/pass-star-red.svg"
                    />

                    <div className="text-center">
                      <span className="starEarn__num">
                        {userData?.redeemable_earning}
                      </span>{" "}
                      <br /> Withdraw
                    </div>
                  </div>
                </button>
                <a href="#" className="notify">
                  <div className="rounded-circle dot" />
                  <img src="/assets/images/icons/bell-icon.svg" alt="" />
                </a>
                <a
                  onClick={(e) => {
                    e.preventDefault();
                    logout();
                  }}
                  className="logout cursor-pointer"
                >
                  <img src="/assets/images/icons/logout-icon.svg" alt="" />
                </a>
              </div>
            </div>
          </div>
          {children}
        </div>
      </div>

      <footer className="footer footer2 mt-2">
        <div className="container">
          <div className="footer-top-strip" />
          <div className="row">
            <div className="col-12 order-3 order-lg-1 col-sm-12 col-lg-3 mb-4 mb-lg-0">
              <div className="footer__logo">
                <img src="/assets/images/footer-logo.svg" alt="..." />
              </div>
              {/* <div className="footer__txt">
                YOU GET TO SKIP THE HUSSEL OF PAYING COVER CHARGES OR STANDING IN
                LONG QUES OR BEING REJECTED ENTRY IN CLUB TITOS, the No. 1 Club of
                Goa, FOR THE NEXT 10 YEARS.
              </div> */}
              <div className="footer__followicons">
                {/* <div className="text-center">
                  <h3 className="mb-3">Follow Us:</h3>
                </div> */}
                <div className="row">
                  <div className="col-6 text-end text-lg-center">
                    <a className="facebook__social" href>
                      <i className="icon-fb" /> <span>Facebook</span>
                    </a>
                  </div>
                  <div className="col-6 text-start text-lg-center">
                    <a className="facebook__social" href>
                      <i className="icon-tw" /> <span>Twitter</span>
                    </a>
                  </div>
                  <div className="col-6 text-end text-lg-center">
                    <a className="facebook__social" href>
                      <i className="icon-li" /> <span>LinkedIn</span>
                    </a>
                  </div>
                  <div className="col-6 text-start text-lg-center">
                    <a className="facebook__social" href>
                      <i className="icon-in" /> <span>Instagram</span>
                    </a>
                  </div>
                </div>
              </div>
              <div className="footer__copytxt">
                © Copyright 2023. All right reserved.
              </div>
            </div>
            <div className="col-12 order-1 order-lg-2 col-md-6 col-lg-6 ps-lg-5 mb-3 mb-lg-0">
              <h3 className="footer__appbtnstitle text-center d-md-none">
                Subscribe to our Newsletters
              </h3>
              <div className="footer__subscribewrapper">
                <form>
                  <div className="footer__subscribe">
                    <input
                      ype="email"
                      required
                      placeholder="Your email address"
                    />
                    <button type="submit">Subscribe</button>
                  </div>
                </form>
              </div>
              <div className="footer__links">
                <div className="row">
                  <div className="col">
                    <ul>
                      <li>
                        <a href>How it works?</a>
                      </li>
                      <li>
                        <a href>Refer Earn</a>
                      </li>
                    </ul>
                  </div>
                  <div className="col">
                    <ul>
                      <li>
                        <a href>Privacy Policy</a>
                      </li>
                      <li>
                        <a href>Terms and Conditions</a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 order-2 order-lg-3 col-md-6 col-lg-3 mb-3 mb-lg-0">
              <div className="footer__appbtns">
                <h3 className="footer__appbtnstitle text-center">
                  Download our app
                </h3>
                <div className="row">
                  <div className="col-6 col-md-12 text-end text-md-center">
                    <a className="footer__appbtn-gplay" href />
                  </div>
                  <div className="col-6 col-md-12 text-start text-md-center">
                    <a className="footer__appbtn-appstore" href />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}
