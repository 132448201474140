import React, { useState } from "react";
import { axiosClient } from "../../constants";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

export default function Signup6({ setStep, token }) {
  const [input, setInput] = useState({
    address1: "",
    address2: "",
    landmark: "",
    state: "",
    town: "",
    city: "",
    pincode: "",
  });
  const onChangeHandler = (e) => {
    setInput({ ...input, [e.target.name]: e.target.value });
  };

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("address_line_1", input.address1);
      data.append("address_line_2", input.address2);
      data.append("landmark", input.landmark);
      data.append("state", input.state);
      data.append("town", input.town);
      data.append("city", input.city);
      data.append("pincode", input.pincode);
      data.append("step", "6");

      const response = await axiosClient.patch(
        "/accounts/user-onboarding",
        data,
        { headers: { Authorization: "Token " + token } }
      );
      Cookies.set("USER_DATA", JSON.stringify(response.data.data));
      toast.success("Data Updated Successfully!!!");
      window.location.href = "/pass";
    } catch (error) {
      console.log(error);
      toast.error("Internal Server Error!!!");
    }
  };
  return (
    <>
      <div>
        <div className="bg-setup bg-setup--login">
          <div className="blobs" />
        </div>
        <div className="main-page">
          <div className="container signup-documentation">
            {/* Top actions btn row */}
            <div className="top-btns">
              <div className="top-btns__left">
                <a className="cursor-pointer" onClick={() => setStep(5)}>
                  <img src="./assets/images/icons/back-icon.svg" alt="" />
                </a>
              </div>
            </div>
            {/* ===================== */}
            <div className="sponsor">
              <div className="sponsor__left">
                <div className="cards123 cards123--signup">
                  <div className="cards123__single">
                    <div className="cards123__bg" />
                    <div className="cards123__img">
                      <div className="cards123__bg-inner" />
                      <img src="../assets/images/signup/img1.png" alt="" />
                    </div>
                  </div>
                  <div className="cards123__single">
                    <div className="cards123__bg" />
                    <div className="cards123__img">
                      <div className="cards123__bg-inner" />
                      <img src="../assets/images/signup/img2.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="sponsor__right">
                <div className="w-100 mx-auto sponsor__right__inner">
                  <div className="auth-logo logo-notop">
                    <img src="./assets/images/header-logo.svg" alt="logo" />
                  </div>
                  <div className="sign-up-header">
                    <p className="fw500">
                      You need to enter address details in the given fields.
                    </p>
                  </div>
                  <form className="form" onSubmit={onSubmit}>
                    <div className="input-text-outer mb-4">
                      <div className="input-bg">
                        <div className="input-bg-inner" />
                      </div>
                      <div className="input-field">
                        <span className="input-field__prefix">
                          <i className="icon icon-home" />
                        </span>
                        <input
                          type="text"
                          placeholder="Address Line l"
                          name="address1"
                          value={input.address1}
                          onChange={onChangeHandler}
                          required
                        />
                      </div>
                    </div>
                    <div className="input-text-outer mb-4">
                      <div className="input-bg">
                        <div className="input-bg-inner" />
                      </div>
                      <div className="input-field">
                        <span className="input-field__prefix">
                          <i className="icon icon-home" />
                        </span>
                        <input
                          type="text"
                          placeholder="Address Line 2"
                          name="address2"
                          value={input.address2}
                          onChange={onChangeHandler}
                          required
                        />
                      </div>
                    </div>
                    <div className="input-text-outer mb-4">
                      <div className="input-bg">
                        <div className="input-bg-inner" />
                      </div>
                      <div className="input-field">
                        <span className="input-field__prefix">
                          <i className="icon icon-tree" />
                        </span>
                        <input
                          type="text"
                          placeholder="Land Mark"
                          name="landmark"
                          value={input.landmark}
                          onChange={onChangeHandler}
                        />
                      </div>
                    </div>
                    <div class="row">
                      <div className="col-lg-6">
                        <div className="input-text-outer mb-4">
                          <div className="input-bg">
                            <div className="input-bg-inner" />
                          </div>
                          <div className="input-field">
                            <span className="input-field__prefix">
                              <i className="icon icon-location" />
                            </span>
                            <input
                              type="text"
                              placeholder="State"
                              name="state"
                              value={input.state}
                              onChange={onChangeHandler}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-text-outer mb-4">
                          <div className="input-bg">
                            <div className="input-bg-inner" />
                          </div>
                          <div className="input-field">
                            <span className="input-field__prefix">
                              <i className="icon icon-location" />
                            </span>
                            <input
                              type="text"
                              placeholder="Town"
                              name="town"
                              value={input.town}
                              onChange={onChangeHandler}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div className="col-lg-6">
                        <div className="input-text-outer mb-4">
                          <div className="input-bg">
                            <div className="input-bg-inner" />
                          </div>
                          <div className="input-field">
                            <span class="input-field__prefix">
                              <i class="icon icon-location"></i>
                            </span>
                            <input
                              name="city"
                              placeholder="City Name"
                              value={input.city}
                              onChange={onChangeHandler}
                              required
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-6">
                        <div className="input-text-outer mb-4">
                          <div className="input-bg">
                            <div className="input-bg-inner" />
                          </div>
                          <div className="input-field">
                            <span className="input-field__prefix">
                              <i className="icon icon-hash" />
                            </span>
                            <input
                              type="text"
                              placeholder="Pin Code"
                              name="pincode"
                              value={input.pincode}
                              onChange={onChangeHandler}
                              required
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="row">
                      {/* <div className="col-lg-6">
                        <div className="input-button-wrapper signup4-responsive">
                          <button type='button' className="input-button input-button--dark">
                            <span className="input-button__inner">
                              <span className="input-button__text">Skip</span>
                              <span className="input-button__icon-outer">
                                <span className="input-button__icon-inner">
                                  <i className="icon icon-double-chevron-dark" />
                                </span>
                              </span>
                            </span>
                          </button>
                        </div>
                      </div> */}

                      <div className="col-lg-6">
                        <div className="input-button-wrapper signup4-responsive">
                          <button className="input-button">
                            <span className="input-button__inner">
                              <span className="input-button__text">Next</span>
                              <span className="input-button__icon-outer">
                                <span className="input-button__icon-inner">
                                  <i className="icon icon-double-chevron-dark" />
                                </span>
                              </span>
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
