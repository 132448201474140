import React, { useEffect, useState } from "react";
import { axiosClient } from "../../constants";
import { toast } from "react-toastify";
import Cookies from "js-cookie";

export default function Signup3({ setStep, input1, setToken }) {
  const [input, setInput] = useState({
    emailCode: "",
    // phoneCode: ''
  });
  const [otpFlag, setOtpFlag] = useState(true);
  const [timer, setTimer] = useState(60);

  useEffect(() => {
    const myTimer = setInterval(reSendDisable, 1000);

    return () => {
      clearInterval(myTimer);
    };
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    try {
      const data = new FormData();
      data.append("email", input1.email);
      data.append("email_otp", input.emailCode);
      // data.append("phone_otp", input.phoneCode);

      const response = await axiosClient.post("/accounts/confirm-otp", data);
      setToken(response?.data?.token);
      Cookies.set("USER_TOKEN", response?.data?.token);

      const data1 = new FormData();
      data1.append("first_name", input1.fName);
      data1.append("last_name", input1.lName);
      data1.append("date_of_birth", input1.dob);
      data1.append("gender", input1.gender);
      data1.append("sponsor_address", input1.referralEmail);
      data1.append("account_type", input1.account_type);
      data1.append("step", "4");

      const response1 = await axiosClient.patch(
        "/accounts/user-onboarding",
        data1,
        { headers: { Authorization: "Token " + response?.data?.token } }
      );
      Cookies.set("USER_DATA", JSON.stringify(response.data.data));
      toast.success("Email and Phone Verified Successfully.");
      Cookies.remove( "OTP_TIME" );
      setStep(5)
      // window.location.href = "/pass";
    } catch (error) {
      console.log(error);
      toast.error("Incorrect OTP! Please Try Again.");
    }
  };

  const reSend = async (type) => {
    try {
      const data = new FormData();
      data.append("email", input1.email);
      data.append("otp_type", type);
      const response = await axiosClient.post("/accounts/resend-otp", data);
      Cookies.set("OTP_TIME", JSON.stringify(new Date()));
      toast.success(`OTP resent successfully sent to your ${type}.`);
    } catch (error) {
      console.log(error);
      toast.error("Internal Server Error!!!");
    }
  };

  const reSendDisable = () => {
    if (Cookies.get("OTP_TIME")) {
      const otpTime = JSON.parse(Cookies.get("OTP_TIME"));
      let time1 = new Date(otpTime);
      time1.setSeconds(time1.getSeconds() + 60);
      let time2 = new Date();
      setTimer(60 - (time2.getSeconds() - time1.getSeconds()));
      if (time1 >= time2) {
        setOtpFlag(true);
      } else {
        setOtpFlag(false);
      }
    }
  };

  return (
    <>
      <div>
        <div className="bg-setup bg-setup--login">
          <div className="blobs" />
        </div>
        <div className="main-page">
          <div className="container signup-documentation">
            {/* Top actions btn row */}
            <div className="top-btns">
              <div className="top-btns__left">
                <a
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.preventDefault();
                    setStep(2);
                  }}
                >
                  <img src="./assets/images/icons/back-icon.svg" alt="" />
                </a>
              </div>
            </div>
            {/* ===================== */}
            <div className="sponsor sponsor-center">
              <div className="sponsor__left signup-5">
                <div className="cards123 cards123--signup">
                  <div className="cards123__single">
                    <div className="cards123__bg" />
                    <div className="cards123__img">
                      <div className="cards123__bg-inner" />
                      <img src="../assets/images/signup/img3.png" alt="" />
                    </div>
                  </div>
                  <div className="cards123__single">
                    <div className="cards123__bg" />
                    <div className="cards123__img">
                      <div className="cards123__bg-inner" />
                      <img src="../assets/images/signup/img4.png" alt="" />
                    </div>
                  </div>
                </div>
              </div>
              <div className="sponsor__right">
                <div className="w-100 mx-auto sponsor__right__inner">
                  <div className="auth-logo">
                    <img src="./assets/images/header-logo.svg" alt="logo" />
                  </div>
                  <div className="sign-up-header signup-text-header">
                    <p className="fw500">
                      You needs to verify your email address and phone number
                    </p>
                  </div>
                  <form className="form signup-form mt-5" onSubmit={onSubmit}>
                    <div className="sign-up-header form-description">
                      <p className="fw400">
                        Code will be sent to {input1.email}
                      </p>
                    </div>
                    <div className="input-text-outer mb-4">
                      <div className="input-bg">
                        <div className="input-bg-inner"></div>
                      </div>
                      <div className="input-field">
                        <input
                          type="text"
                          value={input.emailCode}
                          onChange={(e) =>
                            setInput({ ...input, emailCode: e.target.value })
                          }
                          required
                          placeholder="Email Verification Code"
                        />
                        <button
                          type="button"
                          disabled={otpFlag}
                          onClick={() => reSend("email")}
                          className="input-button send-code"
                        >
                          <span className="input-button__inner">
                            <span className="input-button__text">
                              Resend Code{" "}
                              {otpFlag ? (
                                <span>
                                  <br />
                                  in {timer} sec
                                </span>
                              ) : (
                                ""
                              )}
                            </span>
                          </span>
                        </button>
                      </div>
                    </div>

                    {/* <div className="sign-up-header form-description form-tags">
                      <p className="fw400">
                        Code will be sent to {input1.phone}
                      </p>
                    </div>
                    <div class="input-text-outer">
                      <div class="input-bg">
                        <div class="input-bg-inner">
                        </div>
                      </div>
                      <div class="input-field">
                        <input type="text" value={input.phoneCode} onChange={(e) => setInput({ ...input, phoneCode: e.target.value })} required placeholder="Mobile Verification Code" />
                        <button type="button" disabled={otpFlag} onClick={() => reSend("phone")} class="input-button send-code">
                          <span class="input-button__inner">
                            <span class="input-button__text">Resend Code {otpFlag ? <span><br/>in {timer} sec</span> : ''}</span>
                          </span>
                        </button>
                      </div>
                    </div> */}

                    <div className="signup-btn signup6-btn input-button-wrapper">
                      <button
                        type="submit"
                        className="button text-white button--red button--getstarted signup_form code-btn"
                      >
                        <span className="button__inner">
                          <span className="button__text">Verify</span>
                          <span className="button__iconwrapperouter">
                            <span className="button__iconwrapperinner">
                              <i className="icon icon-double-chevron-dark" />
                            </span>
                          </span>
                        </span>
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
